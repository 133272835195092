import { render, staticRenderFns } from "./footer-block.vue?vue&type=template&id=583dd264&"
import script from "./footer-block.vue?vue&type=script&lang=js&"
export * from "./footer-block.vue?vue&type=script&lang=js&"
import style0 from "./footer-block.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconRu: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-ru/index.vue').default,RzIconKgz: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-kgz/index.vue').default,RzIconVk: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-vk/index.vue').default,RzIconVisa: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-visa/index.vue').default,RzIconMir: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-mir/index.vue').default,RzIconTkp: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-tkp/index.vue').default,RzIconIata: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-iata/index.vue').default,RzIconMastercard: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-mastercard/index.vue').default,RzIconMaestro: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-maestro/index.vue').default,RzIconDemir: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-demir/index.vue').default,RzIconElsom: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-elsom/index.vue').default,RzIconMbank: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-mbank/index.vue').default})
