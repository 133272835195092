import Vue from 'vue';

export default function (context) {
  // Add the userAgent property to the context
  const userAgent = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent;
  const isMobile = /iPhone|iPad|iPod|Android|Windows Phone/.test(userAgent);
  context.store.commit('setMobile', isMobile);
  Vue.prototype.$mobile = isMobile;
}