import Vue from 'vue';
import {mapGetters} from 'vuex';
import filters from 'razlet-sdk/lib/filters/base';


Vue.mixin({
  data() {
    return {
      mainPhone: '+7 (391) 222-3-222',
      globalMountKey: 0,
      phoneLink: 'tel:+73912223222',
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isOffline', 'user', 'currentTheme']),
    isDev() {
      return !/\/\/api.razlet.ru/.test(process.env.asb3URL);
    },
    showLogin() {
      return process.env.auth !== 'none';
    },
    isRolesLoaded() {
      return !!(this.user?.model?.roles?.length);
    },
    userRoles() {
      if (!this.isRolesLoaded) return [];
      return this.user.model.roles;
    },
    isAgent() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_AGENT');
    },  
    isCorporate() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_CORPORATE');
    },
    isCashier() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_CASHER');
    },
    isAdmin() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_ADMIN');
    },
    isParseDocumentAllowed() {
      return this.isDev && (this.isCashier || this.isAdmin);
    },
    isKg() {
      return process.env.site && process.env.site === 'kg';
    },
    isUser() {
      return typeof this.$store.state.user !== 'undefined' && this.user.model && !!Object.keys(this.user.model).length;
    },
    currency() {
      return process.env.currency || '';
    },
    userCurrency() {
      return this.isUser ? this.user.model.currency : this.currency;
    },
  },
  mounted() {
    this.changeColorScheme();
  },
  methods: {
    changeColorScheme () {
      const root = document.getElementsByTagName('html')[0];
      root.className = this.currentTheme;
    },
  },
});

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const COUNTRIES = {
  RUB: 'ru',
  USD: 'en',
  'KGS': 'ru-KG',
  'KGS-kg': 'ru-KG',
  'KGS-ru': 'ru-KG',
  'KGS-en': 'ru-US',
};

const getKGCurrency = (currency) => `${currency}-${Vue.prototype.$locale}`;
const getCurrency = (currency) => currency === 'KGS' ? getKGCurrency(currency) : currency;

Vue.prototype.$localizeCurrency = (value, currency) => `${Vue.prototype.$localize.n(value, 'currency', COUNTRIES[getCurrency(currency)])}`;

const app = new Vue({});

const globalVars = {
  defaultSearch: 'KJA0000MOW1000es',
  showAgreement: true,
  sendMetaMarkers: true,
  newRequestsEnabled: true,
  openError: {},
  mobile: false,
};

Object.keys(globalVars).forEach(key => {
  app[key] = globalVars[key];

  if (typeof Vue.prototype[`$${key}`] === 'undefined') {
    Object.defineProperty(Vue.prototype, `$${key}`, {
      get() {
        return app[key];
      },
      set(value) {
        app[key] = value;
      },
    });
  }
});

Vue.prototype.$isFalsy = (value) => value === null || typeof value === 'undefined' || Number.isNaN(value) || value === '';
Vue.prototype.$isObject = (value) => !Vue.prototype.$isFalsy(value) && typeof value === 'object' && !Array.isArray(value);
Vue.prototype.$isValidObject = (value) => Vue.prototype.$isObject(value) && Object.keys(value).length;
Vue.prototype.$isString = (value) => !Vue.prototype.$isFalsy(value) && typeof value === 'string';