import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d99659aa = () => interopDefault(import('../src/pages/bad-access.vue' /* webpackChunkName: "pages/bad-access" */))
const _44c6946d = () => interopDefault(import('../src/pages/booking/index.vue' /* webpackChunkName: "pages/booking/index" */))
const _1f1662c8 = () => interopDefault(import('../src/pages/citylist.vue' /* webpackChunkName: "pages/citylist" */))
const _4af83192 = () => interopDefault(import('../src/pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _b548e28c = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _19ca5895 = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _f3d3212c = () => interopDefault(import('../src/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _3f23afe6 = () => interopDefault(import('../src/pages/account/operations.vue' /* webpackChunkName: "pages/account/operations" */))
const _ca8289f4 = () => interopDefault(import('../src/pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _7307c3bd = () => interopDefault(import('../src/pages/account/passengers/index.vue' /* webpackChunkName: "pages/account/passengers/index" */))
const _33aba0d8 = () => interopDefault(import('../src/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _22fce8e8 = () => interopDefault(import('../src/pages/account/requests/index.vue' /* webpackChunkName: "pages/account/requests/index" */))
const _52179d19 = () => interopDefault(import('../src/pages/account/tickets/index.vue' /* webpackChunkName: "pages/account/tickets/index" */))
const _57b3d346 = () => interopDefault(import('../src/pages/account/upsale/index.vue' /* webpackChunkName: "pages/account/upsale/index" */))
const _15a4fea1 = () => interopDefault(import('../src/pages/account/passengers/create.vue' /* webpackChunkName: "pages/account/passengers/create" */))
const _64547ed6 = () => interopDefault(import('../src/pages/account/requests/create.vue' /* webpackChunkName: "pages/account/requests/create" */))
const _56a72f78 = () => interopDefault(import('../src/pages/account/upsale/booking/_id.vue' /* webpackChunkName: "pages/account/upsale/booking/_id" */))
const _93299f26 = () => interopDefault(import('../src/pages/account/upsale/order/_id.vue' /* webpackChunkName: "pages/account/upsale/order/_id" */))
const _2181f940 = () => interopDefault(import('../src/pages/account/upsale/timetable/_id.vue' /* webpackChunkName: "pages/account/upsale/timetable/_id" */))
const _5c3d9165 = () => interopDefault(import('../src/pages/account/passengers/_id.vue' /* webpackChunkName: "pages/account/passengers/_id" */))
const _99fff560 = () => interopDefault(import('../src/pages/account/requests/_id.vue' /* webpackChunkName: "pages/account/requests/_id" */))
const _6b2b0e67 = () => interopDefault(import('../src/pages/bid-return/create/_id.vue' /* webpackChunkName: "pages/bid-return/create/_id" */))
const _65387f2a = () => interopDefault(import('../src/pages/bid-return/_id.vue' /* webpackChunkName: "pages/bid-return/_id" */))
const _b04e63d6 = () => interopDefault(import('../src/pages/booking/_id.vue' /* webpackChunkName: "pages/booking/_id" */))
const _6a1c0d66 = () => interopDefault(import('../src/pages/order/_id/index.vue' /* webpackChunkName: "pages/order/_id/index" */))
const _576688a9 = () => interopDefault(import('../src/pages/search/_uid.vue' /* webpackChunkName: "pages/search/_uid" */))
const _54430450 = () => interopDefault(import('../src/pages/order/_id/refund/index.vue' /* webpackChunkName: "pages/order/_id/refund/index" */))
const _09b30f9e = () => interopDefault(import('../src/pages/order/_id/refund/voluntary.vue' /* webpackChunkName: "pages/order/_id/refund/voluntary" */))
const _0c7b5874 = () => interopDefault(import('../src/pages/flight/_departure/_arrival.vue' /* webpackChunkName: "pages/flight/_departure/_arrival" */))
const _4a1e6f22 = () => interopDefault(import('../src/pages/news/_.vue' /* webpackChunkName: "pages/news/_" */))
const _489828ba = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0cf7b930 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bad-access",
    component: _d99659aa,
    meta: {"name":"bad-access-page","layout":"index"},
    name: "bad-access"
  }, {
    path: "/booking",
    component: _44c6946d,
    meta: {"name":"booking-index"},
    name: "booking"
  }, {
    path: "/citylist",
    component: _1f1662c8,
    meta: {"name":"citylist-page","layout":"index"},
    name: "citylist"
  }, {
    path: "/contacts",
    component: _4af83192,
    meta: {"name":"contacts","layout":"index"},
    name: "contacts"
  }, {
    path: "/login",
    component: _b548e28c,
    meta: {"name":"login-page","layout":"index"},
    name: "login"
  }, {
    path: "/news",
    component: _19ca5895,
    meta: {"name":"news-list-page","layout":"index"},
    name: "news"
  }, {
    path: "/search",
    component: _f3d3212c,
    meta: {"name":"search-index"},
    name: "search"
  }, {
    path: "/account/operations",
    component: _3f23afe6,
    meta: {"name":"operations-page","layout":"account","access":["ROLE_USER"]},
    name: "account-operations"
  }, {
    path: "/account/orders",
    component: _ca8289f4,
    meta: {"name":"orders-page","layout":"account","access":["ROLE_USER"]},
    name: "account-orders"
  }, {
    path: "/account/passengers",
    component: _7307c3bd,
    meta: {"name":"passengers-page","layout":"account","access":["ROLE_USER"],"auth":true},
    name: "account-passengers"
  }, {
    path: "/account/profile",
    component: _33aba0d8,
    meta: {"name":"profile-page","layout":"account","access":["ROLE_USER"]},
    name: "account-profile"
  }, {
    path: "/account/requests",
    component: _22fce8e8,
    meta: {"name":"account-requests","layout":"account","access":["ROLE_CORPORATE","ROLE_AGENT","ROLE_CASHER"],"authRequired":true},
    name: "account-requests"
  }, {
    path: "/account/tickets",
    component: _52179d19,
    meta: {"name":"account-tickets","layout":"account","authRequired":true},
    name: "account-tickets"
  }, {
    path: "/account/upsale",
    component: _57b3d346,
    meta: {"name":"upsale-airport","layout":"account","access":["ROLE_AIRPORT_CASHER"]},
    name: "account-upsale"
  }, {
    path: "/account/passengers/create",
    component: _15a4fea1,
    meta: {"name":"passengers-create-page","layout":"account","access":["ROLE_USER"]},
    name: "account-passengers-create"
  }, {
    path: "/account/requests/create",
    component: _64547ed6,
    meta: {"name":"account-requests-create","layout":"account","access":["ROLE_CORPORATE","ROLE_AGENT","ROLE_CASHER"],"authRequired":true},
    name: "account-requests-create"
  }, {
    path: "/account/upsale/booking/:id?",
    component: _56a72f78,
    meta: {"name":"upsale-booking","layout":"account","access":["ROLE_AIRPORT_CASHER"]},
    name: "account-upsale-booking-id"
  }, {
    path: "/account/upsale/order/:id?",
    component: _93299f26,
    meta: {"name":"upsale-order","layout":"account","access":["ROLE_AIRPORT_CASHER"]},
    name: "account-upsale-order-id"
  }, {
    path: "/account/upsale/timetable/:id?",
    component: _2181f940,
    meta: {"name":"upsale-timetable","layout":"account","access":["ROLE_AIRPORT_CASHER"]},
    name: "account-upsale-timetable-id"
  }, {
    path: "/account/passengers/:id",
    component: _5c3d9165,
    meta: {"name":"passengers-update-page","layout":"account","access":["ROLE_USER"]},
    name: "account-passengers-id"
  }, {
    path: "/account/requests/:id",
    component: _99fff560,
    meta: {"name":"account-requests-info","layout":"account","access":["ROLE_CORPORATE","ROLE_AGENT","ROLE_CASHER"],"authRequired":true},
    name: "account-requests-id"
  }, {
    path: "/bid-return/create/:id?",
    component: _6b2b0e67,
    meta: {"name":"bid-return","layout":"index"},
    name: "bid-return-create-id"
  }, {
    path: "/bid-return/:id?",
    component: _65387f2a,
    meta: {"name":"bid-return","layout":"index"},
    name: "bid-return-id"
  }, {
    path: "/booking/:id",
    component: _b04e63d6,
    meta: {"name":"booking-id","layout":"index"},
    name: "booking-id"
  }, {
    path: "/order/:id",
    component: _6a1c0d66,
    meta: {"name":"order-id","layout":"index"},
    name: "order-id"
  }, {
    path: "/search/:uid",
    component: _576688a9,
    meta: {"name":"search-id","layout":"default"},
    name: "search-uid"
  }, {
    path: "/order/:id?/refund",
    component: _54430450,
    meta: {"name":"refund-page","layout":"index"},
    name: "order-id-refund"
  }, {
    path: "/order/:id?/refund/voluntary",
    component: _09b30f9e,
    meta: {"name":"voluntary-refund-page","layout":"index"},
    name: "order-id-refund-voluntary"
  }, {
    path: "/flight/:departure?/:arrival?",
    component: _0c7b5874,
    meta: {"name":"flight-page","layout":"index"},
    name: "flight-departure-arrival"
  }, {
    path: "/news/*",
    component: _4a1e6f22,
    meta: {"name":"news-page","layout":"index"},
    name: "news-all"
  }, {
    path: "/",
    component: _489828ba,
    meta: {"name":"index","layout":"index"},
    name: "index"
  }, {
    path: "/*",
    component: _0cf7b930,
    meta: {"name":"headless-page","layout":"index"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
