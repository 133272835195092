import BaseStore from 'razlet-sulu-sdk/lib/store/base';
// import AirportStore from 'razlet-sulu-sdk/lib/store/airport';

export default async ({ isClient, store }) => {
  const opts = {}
  if (isClient) {
    opts.preserveState = true;
  }
  store.registerModule('sulu', BaseStore, opts);
  // store.registerModule('sulu/airport', AirportStore, opts);
};